<template>
  <div>
    <!-- Platform User table -->
    <manage-user-tables v-if="store.mmData" :toggleCreateUser="toggleCreateUser" :toggleEditUser="toggleEditUser" :setEditValue="setEditValue" ></manage-user-tables>

    <!-- create Platform User modal -->
    <modal :show.sync="modals.createUser"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create User</h3>
      <create-user-forms class="mm-modal mm-create" :toggleCreateUser="toggleCreateUser" ></create-user-forms>
    </modal>

    <!-- edit Platform User modal -->
    <modal :show.sync="modals.editUser"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit User</h3>
      <edit-user-forms class="mm-modal mm-create" :toggleEditUser="toggleEditUser" :editValue="editValue" ></edit-user-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import ManageUserTables from 'src/components/Dashboard/Views/Tables/ManageUserTables.vue'
  import CreateUserForms from 'src/components/Dashboard/Views/Forms/CreateUserForms.vue'
  import EditUserForms from 'src/components/Dashboard/Views/Forms/EditUserForms.vue'

  export default {
    components: {
      ManageUserTables,
      CreateUserForms,
      EditUserForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createUser: false,
          editUser: false,
        },
        editValue: {
          mundialprofile: '',
          email: '',
          first_name: '',
          last_name: '',
          is_active: '',
          og_active: '',
        },
      }
    },
    methods: {
      setEditValue(obj) {
        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          this.editValue[tmpKey] = obj[tmpKey]
        }
      },
      toggleCreateUser() {
        this.modals.createUser = !this.modals.createUser
      },
      toggleEditUser() {
        this.modals.editUser = !this.modals.editUser
      },
    },
    async mounted () {
      //await store.initMundial()

      console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>
