<template>
  <div>
    <div class="text-right cadmusDiv cadmusTopDiv">

      <!-- OG
      <button class="btn btn-fill btn-wd" :class="intervalRunning ? 'btn-danger' : 'btn-success'" @click="toggleInterval">
        {{ intervalRunning ? 'Pause' : 'Resume' }}
      </button>
      END -->

      <button class="btn btn-fill btn-wd btn-success" @click="incrementCount" v-if="!intervalRunning && count < keyMax">
        {{ 'Resume' }}
      </button>
      <button class="btn btn-fill btn-wd btn-danger" @click="toggleFetch(false)" v-else-if="!intervalRunning && delayFlag">
        {{ 'Halt' }}
      </button>
      <button class="btn btn-fill btn-wd btn-success" @click="toggleFetch(true)" v-else-if="!intervalRunning && !delayFlag && !fetchFlag">
        {{ 'Fetch' }}
      </button>
      <button class="btn btn-fill btn-wd btn-danger" @click="toggleInterval" v-else>
        {{ 'Pause' }}
      </button>
      
      <hr/>
    </div>
    <div class="cadmusDiv" v-if="store.mmData && !store.mmData.user.display_tc" v-for="(item, index) in items" :key="index" :style="{ display: item.display, 'min-height': index == keyMax ? '25vh' : 0 }">
      <h6>{{ !index && item.details.doc.title ? item.details.doc.title : item.text }}</h6>
      <hr v-if="index !== keyMax"/>
      <cadmus-green-loader-bar class="cadmusLoader" v-else-if="delayFlag"></cadmus-green-loader-bar>
      <hr v-else />


      <!-- Linked Page URLs -->
      <a :href="item.details.url.replace('soccergator.io','433futbol.com')" v-if="!index" target="_blank" rel="noopener">{{ item.details.url.replace('soccergator.io','433futbol.com') }}</a>
 
      <!-- TEMP
      <div class="thumbnail-container" v-if="!index">
        <a :href="item.details.url" target="_blank" rel="noopener">
          <div class="thumbnail">
            <iframe :src="item.details.url" frameborder="0"></iframe>
          </div>
        </a>
      </div>
      -->

      <!-- Page Information -->
      <div v-else-if="index == 1">
        <p v-if="item.details.doc.author">Author(s): {{ item.details.doc.author }}</p>
        <p v-if="item.details.doc.tags">Tags: {{ item.details.doc.tags.replace(/;/g,'; ') }}</p>
        <p>Language: {{ store.iso6391[item.details.lang.replace('__label__','')] }} - {{ (item.details.prob * 100).toFixed(1) }}%</p>
        <p>Word Count: {{ item.details.count }}</p>
        <p>Distinct Words: {{ item.details.distinct_count }}</p>        
      </div>


      <!-- Raw Page Content
      <div v-else-if="index == 2">
        <p v-if="item.details.doc.title">Title: {{ item.details.doc.title }}</p>
        <p v-if="item.details.doc.author">Author(s): {{ item.details.doc.author }}</p>
        <p v-if="item.details.doc.date">Date: {{ item.details.doc.date }}</p>
        <p v-if="item.details.bold.length">Highlights:</p>
        <hr v-if="item.details.bold.length" />
        <ol v-if="item.details.bold.length" v-for="highlight in item.details.bold">{{ highlight }}</ol>
        <hr/>
        <p>{{ item.details.text }}</p>
        <p v-for="(detail, idx) in item.details.label" class="centerText">
          {{ store.iso6391[detail.replace('__label__','')] }} - {{ (item.details.prob[idx] * 100).toFixed(1) }}%
        </p>
      </div> END -->

      <div v-else-if="index == 2">
        <p>{{ item.details.text }}</p>
      </div>

      <!-- Pre-Processed Content -->
      <div v-else-if="index == 3">
        <p>{{ item.details.clean_text }}</p>
      </div>


      <!-- Image Analysis -->
      <div v-else-if="index == 4" v-for="detail in item.details">
        <!-- {{ item.details }} -->

        <div class="row">
          <div class="col-md-4">
            <img :src="`${detail.image_url.replace('soccergator.io','433futbol.com')}`">
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-6 col-md-3">
                <h6>MODERATION</h6>

                <p v-if="detail.moderation.length" v-for="d in detail.moderation.slice(0,10)">
                  {{ d[0] }} - {{ d[2].toFixed(1) }}%
                </p>
                <p v-if="!detail.moderation.length">
                  NO MATCHES
                </p>
              </div>
              <div class="col-sm-6 col-md-3">
                <h6>LABELS</h6>

                <p v-if="detail.labels.length" v-for="d in detail.labels.filter(function(item) { return item[1] > 50.0}).slice(0,10)">
                  {{ d[0] }} - {{ d[1].toFixed(1) }}%
                </p>
                <p v-if="!detail.labels.filter(function(item) { return item[1] > 50.0}).length">
                  NO MATCHES
                </p>
              </div>
              <div class="col-sm-6 col-md-3">
                <h6>TEXT</h6>

                <p v-if="detail.text.length" v-for="d in detail.text.filter(function(item) { return item[1] > 50.0}).slice(0,10)">
                  {{ d[0] }} - {{ d[1].toFixed(1) }}%
                </p>
                <p v-if="!detail.text.filter(function(item) { return item[1] > 50.0}).length">
                  NO MATCHES
                </p>
              </div>
              <div class="col-sm-6 col-md-3">
                <h6>CELEBRITY</h6>

                <p v-if="detail.celebrity.length" v-for="d in detail.celebrity.filter(function(item) { return item[1] > 50.0}).slice(0,10)">
                  {{ d[0] }} - {{ d[1].toFixed(1) }}%
                </p>
                <p v-if="!detail.celebrity.filter(function(item) { return item[1] > 50.0}).length">
                  NO MATCHES
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr v-if="item.details.length > 1" />

      </div>


      <!-- Sentiment Analysis -->
      <div v-else-if="index == 5">
        <p>Positive: {{ (item.details.positive * 100).toFixed(1) }}%</p>
        <p>Neutral: {{ (item.details.neutral * 100).toFixed(1) }}%</p>
        <p>Negative: {{ (item.details.negative * 100).toFixed(1) }}%</p>
      </div>

      <!-- Brand Safe Analysis -->
      <div v-else-if="index == 6">
        <p>Contextual Brand Safe Results: Brand Safe - {{ (item.details.text * 100).toFixed(1) }}%</p>
        <p>Image Brand Safe Results: Brand Safe - {{ (item.details.image * 100).toFixed(1) }}%</p>
      </div>


      <!-- Matching Contextual Segments -->
      <p v-else-if="index == 7" v-for="detail in item.details.segments.toSorted( (a, b) => b[1] - a[1] )">
        {{ store.idMundialSegmentDict[ detail[0] ].replace(/:/g,'/') }} {{ item.details.show_percent ? `: ${ (detail[1] * 100).toFixed(1) }%` : '' }}
      </p>

      <!-- Matching Campaigns -->
      <p v-else-if="index == 8" v-for="detail in item.details.campaigns.toSorted( (a, b) => b[1] - a[1] )">
        {{ detail[0] }} {{ item.details.show_percent ? `: ${ (detail[1] * 100).toFixed(1) }%` : '' }}
      </p>

      <!-- Retrieving Next -->
      <div class="text-right cadmusNext" v-else-if="index == keyMax">
        <button class="btn btn-fill btn-wd btn-danger" @click="toggleFetch(false)" v-if="delayFlag">
          {{ 'Halt' }}
        </button>
        <button class="btn btn-fill btn-wd btn-danger" disabled v-else>
          {{ 'Halt' }}
        </button>
        <button class="btn btn-fill btn-wd btn-success" @click="toggleFetch(true)" v-if="!fetchFlag">
          {{ 'Fetch' }}
        </button>
        <button class="btn btn-fill btn-wd btn-success" disabled v-else>
          {{ 'Fetch' }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import CadmusGreenLoaderBar from 'src/components/Dashboard/CadmusGreenLoaderBar'

  const cadmusDict = {
    0: 'Linked Page URLs',
    1: 'Page Information',
    2: 'Raw Page Content',
    3: 'Pre-Processed Content',
    4: 'Image Analysis',
    5: 'Sentiment Analysis',
    6: 'Brand Safe Analysis',
    7: 'Matching Contextual Segments',
    8: 'Matching Campaigns',
    9: 'Retrieving Next Request ...',
  }, keyMax = parseInt(Object.keys(cadmusDict).sort((a,b) => parseInt(b) - parseInt(a))[0]);

  export default {
    components: {
      CadmusGreenLoaderBar,
    },
    data() {
      return {
        store,
        keyMax,
        items: [],
        delay: null,
        timer: null,
        timerMap: {
          0: [0.25, 0.75],
          1: [0.5, 1.0],
          2: [1.0, 2.0],
          3: [1.0, 1.75],
          4: [1.75, 5],
          5: [0.5, 1.25],
          6: [0.5, 1.25],
          7: [0.5, 1.25],
          8: [0.5, 1.25],
          9: [0.5, 1.25],
        },
        delayFlag: true,
        fetchFlag: false,
        idxMax: 0,
        idx: 0,
        count: 0,
        intervalRunning: false,
      };
    },
    methods: {
      async startTimer() {
        if (this.items.length === 0) {
          // Reset idx
          if (this.idx > this.idxMax) this.idx = 0

          // Init Cadmus
          if (!this.idx) {
            await store.initCLD()

            this.idxMax = store.mmData.cadmus.length - 1
          }

          console.log({idx:this.idx, idxMax:this.idxMax})

          console.log({cadmus:store.mmData.cadmus})

          const cadmusDetails = store.mmData.cadmus[this.idx];
          for (let i = 0; i < Object.keys(cadmusDict).length; i++) {
            console.log({i})

            const tmpSection = cadmusDict[i],
            tmpItem = { text: `${tmpSection}`, display: 'none' };
            
            switch(tmpSection) {
              case 'Linked Page URLs':
                tmpItem.details = {}
                tmpItem.details.url = cadmusDetails.url
                tmpItem.details.doc = cadmusDetails.page_json.doc
                break;
              case 'Page Information':
                tmpItem.details = {}
                tmpItem.details.lang = cadmusDetails.page_json.lang_label[0]
                tmpItem.details.prob = cadmusDetails.page_json.lang_prob[0]
                tmpItem.details.doc = cadmusDetails.page_json.doc
                tmpItem.details.count = cadmusDetails.page_json.word_count
                tmpItem.details.distinct_count = cadmusDetails.page_json.distinct_word_count
                break;
              case 'Raw Page Content':
                tmpItem.details = {}
                tmpItem.details.text = cadmusDetails.page_json.page_text
                break;
              case 'Pre-Processed Content':
                tmpItem.details = {}
                tmpItem.details.clean_text = cadmusDetails.page_json.clean_page_text
                break;
              case 'Image Analysis':
                tmpItem.details = cadmusDetails.image_json ? cadmusDetails.image_json : [] 
                break;
              case 'Sentiment Analysis':
                tmpItem.details = cadmusDetails.results_json.sentiment
                break;
              case 'Brand Safe Analysis':
                tmpItem.details = {}
                //tmpItem.details.contextual_safe = Math.floor(Math.random() * 26) + 75
                //tmpItem.details.image_safe = Math.floor(Math.random() * 26) + 75

                tmpItem.details.text = cadmusDetails.results_json.bsafe.text
                tmpItem.details.image = cadmusDetails.results_json.bsafe.image
                break;
              case 'Matching Contextual Segments':
                tmpItem.details = {}

                //store.idMundialSegmentDict

                tmpItem.details.segments = cadmusDetails.results_json.segments
                tmpItem.details.show_segments = cadmusDetails.show_segments
                tmpItem.details.show_percent = cadmusDetails.show_percent
                break;
              case 'Matching Campaigns':
                tmpItem.details = {}

                tmpItem.details.campaigns = cadmusDetails.results_json.campaigns
                tmpItem.details.show_campaigns = cadmusDetails.show_campaigns
                tmpItem.details.show_percent = cadmusDetails.show_percent
                break;
              default:
                tmpItem.details = ''
            }

            this.items.push(tmpItem);
          }
        }

        await this.incrementCount()

      },
      async incrementCount() {
            this.intervalRunning = true;

            // Inner loop to increment
            while (this.intervalRunning) {
              if (this.count > Object.keys(cadmusDict).length - 1) {
                this.resetCount();
                break;

              } else {
                await this.getRandomDelay()

                if (this.intervalRunning) {
                  //this.items[this.count].display = 'block';
                  //this.count++;

                  switch( cadmusDict[this.count] ){
                    case 'Matching Contextual Segments':
                      this.items[this.count].display = this.items[this.count].details.show_segments ? 'block' : 'none';
                      break;
                    case 'Matching Campaigns':
                      this.items[this.count].display = this.items[this.count].details.show_campaigns ? 'block' : 'none';
                      break;
                    default:
                      this.items[this.count].display = 'block';
                  }
                  this.count++;

                  // Reset Next options
                  if (this.count > Object.keys(cadmusDict).length - 1) {
                    this.intervalRunning = false;
                    this.fetchFlag = false;
                    this.delayFlag = true;

                    this.delay = setTimeout(() => this.incrementCount(), 30000);
                  }
                }

              }
            }
      },
      async getRandomDelay() {
        const count = this.count,
        tmpMin = this.timerMap.hasOwnProperty(count) ? this.timerMap[count][0] * 1000 : 0,
        tmpMax = this.timerMap.hasOwnProperty(count) ? this.timerMap[count][1] * 1000 : 0,
        min = Math.ceil(tmpMin),
        max = Math.floor(tmpMax),
        ms = Math.floor(Math.random() * (max - min + 1)) + min;

        console.log({count, min, max, ms});

        return await new Promise(resolve => setTimeout(resolve, ms));
      },
      getRandomInteger() {
        const tmpCount = this.count,
        tmpMin = this.timerMap[tmpCount][0],
        tmpMax = this.timerMap[tmpCount][1],
        min = Math.ceil(tmpMin),
        max = Math.floor(tmpMax);

        console.log({min, max});

        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      toggleInterval() {
        this.intervalRunning = !this.intervalRunning;
      },
      toggleFetch(bool) {
        clearTimeout(this.delay)
        this.fetchFlag = bool

        if (bool) {
          this.incrementCount()
        } else {
          this.intervalRunning = bool
          this.delayFlag = bool
        }
      },
      resetCount() {
        if (this.intervalRunning) {
          this.items = []
          this.count = 0

          this.idx++
          this.startTimer()
        }
      },
    },
    async mounted () {
      await store.initMundial()
      store.colsDict = {}
      store.idMundialSegmentDict = {}

      // Add router to push to Login page upon expired session
      // Consider moving router to router.js (currently main.js)
      if (store.mmData) {
        store.session_router = this.$router

        // Adjust sessionRange dates only when outside mmRange
        store.mmData.adjust_custom = false
      }
      console.log({store})

      // Mundial Segment
      const mm_segment_options = store.mmData.mm_segment_options, mmSegmentLen = mm_segment_options.length;
      for (let r = 0; r < mmSegmentLen; r++) {
        const tmpRow = mm_segment_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

        store.idMundialSegmentDict[tmpId] = tmpName
      }

      // Initiate CLD
      this.startTimer()
    },
    unmounted () {
      clearInterval(this.timer);
    }
  }
</script>
<style>
.cadmusDiv > div > hr {
    width: 75%;
}
.imgContainer {
    display: block;
    text-align: center;
    width: 50%;
}
.text-right button,
.cadmusNext button {
    margin: 1em;
}
.cadmusLoader {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    background: lightgreen;
    box-sizing: content-box;
    height: 2px;
    overflow: visible;
}
.cadmusDiv {
    margin: 2em 1em;
    text-align: left;
    padding: 1em;
    position: relative;
}
.cadmusDiv p {
    line-height: 125%;
    overflow-wrap: anywhere;
}
.cadmusTopDiv {
    margin-top: -2em;
}
.centerText {
    text-align: center !important;
}
.mundialBorders {
    background-color: transparent !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.mundialHeader {
    border-top: none;
    margin-top: -1em;
    margin-bottom: 3em;
}
.mundial-default.el-select .el-input input {
    background-color: #fff;
    border-color: #ddd!important;
    border-width: 2px;
    border-radius: 12px;
    color: #66615b;
}
.el-date-editor .el-range__close-icon {
    display: none;
}
.select-default.el-select .el-input .el-input__icon {
    border-radius: 20px;
    height: 36px;
    width: 30px;
    line-height: 36px;
}
.select-default.el-select-dropdown__item.selected, .select-default.el-select-dropdown__item.selected.hover {
    background-color: #ddd;
    color: #66615b;
}
.el-range-editor.is-active, .el-range-editor.is-active:hover {
    border-color: #9a9a9a;
}
.daterange-mundial {
    border-radius: 12px !important;
    border: 2px solid #ddd !important;
}
.mundial-vcenter {
    display: table;
}
.mundial-vcenter button {
    margin-top: 1em;
}
.mundial-vcenter > .mundial-a {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}
.mundial-vcenter > .btn-mundial-reset {
    float: right;
}
.btn-mundial-reset:hover, .btn-mundial-reset:focus {
    background-color: #66615b !important;
}
.btn-mundial-avails:hover, .btn-mundial-avails:focus {
    background-color: #51bcda !important;
}
</style>
